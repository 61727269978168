<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('loyalty.insights.userpoints')">
    <CCol col="12" xl="12">
      <loyaltyPointsUpdates :platformPermissions="platformPermissions" :showHeader="true" :itemsPerPage="10"/>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loyaltyPointsUpdates from '@/components/loyalty/loyaltyPointsUpdates.vue'
import noPermission from '@/components/common/noPermission.vue';

export default {
  name: 'EmployeePointsUpdates',
  components: {
    loyaltyPointsUpdates,
    noPermission
  },
  data: () => {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false
    }
  },
  methods: {
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }       
  },
  mounted: function(){
    this.getPlatformPermissions();
  }
}
</script>
